.fade-in {
    -webkit-animation: project-text-fade-in .5s linear both;
    animation: project-text-fade-in .5s linear both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-2-10 13:3:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

@keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.3em;
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.3em;
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation delay-fade-in
 * ----------------------------------------
 */
@-webkit-keyframes delay-fade-in {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes delay-fade-in {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation slide-in
 * ----------------------------------------
 */
@-webkit-keyframes slide-in {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@keyframes slide-in {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

/**
 * ----------------------------------------
 * animation project-text-fade-in
 * ----------------------------------------
 */
@-webkit-keyframes project-text-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes project-text-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}