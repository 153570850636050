* {
  margin: 0;
  padding: 0;
}

:root {
  --bg-primary: #ffd89d;
  --bg-secondary: #221717;
  background-color: var(--bg-primary);
  color: var(--bg-secondary);
  font-family: "Inter Tight", sans-serif;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

body, html {
  overflow-x: hidden;
}