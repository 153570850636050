.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em;
}

.project-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg-primary);
    width: 45em;
    height: 25em;
    margin-top: 2.3em;
    border: 1em solid var(--bg-secondary);
}

.project-image-container>img {
    width: 100vw;
}

.projects-content {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
    z-index: 2;
    -webkit-animation: delay-fade-in 1.5s ease-in both;
    animation: delay-fade-in 1.5s ease-in both;
}

.project-display {
    display: flex;
    justify-content: flex-end;
    z-index: 1;
    height: min-content;
}

.brackets-wrapper {
    display: flex;
    align-items: center;
    color: var(--bg-primary);
}

.projects-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    width: 22em;
    margin-top: 3em;
    color: var(--bg-primary);
}

/**
 * ----------------------------------------
 * Text brackets
 * ----------------------------------------
 */
.brackets-wrapper:before {
    content: '[';
    line-height: 0;
    font-size: 26rem;
    margin-right: 0.1em;
}

.brackets-wrapper:after {
    content: ']';
    font-size: 26rem;
    margin-left: 0.1em;
}

/** ------------------------------------ **/

.project-title {
    font-size: 2rem;
    line-height: 0;
    font-family: 'Teko';
}

.project-links>a {
    position: relative;
    color: var(--bg-primary);
    margin: 1em;
    margin-top: 0;
    text-decoration: none;
}

.project-links>a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--bg-primary);
    transform-origin: bottom right;
    transition: transform 0.2s ease-out;
}

.project-description {
    line-height: 1.5em;
    text-align: center;
    padding: 1em;
}

.project-tech {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5em;
    margin-top: 1em;
}

.project-tech>li {
    list-style-type: none;
    color: var(--bg-secondary);
    background-color: var(--bg-primary);
    width: min-content;
    padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 2em;
}

.projects-nav {
    display: flex;
    z-index: 2;
    gap: 1em;
    -webkit-animation: delay-fade-in 1.5s ease-in both;
    animation: delay-fade-in 1.5s ease-in both;
}

.projects-nav>div {
    cursor: pointer;
    width: 2em;
    height: 2em;
    border: 1px solid var(--bg-primary);
    border-radius: 50%;
}

.selected {
    background-color: var(--bg-primary);
}

.slide-bg-container {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    width: 80vw;
    height: 100vh;
    transition: width .2s;
}

.slide-bg {
    justify-self: center;
    height: 100vh;
    background-color: var(--bg-secondary);
    -webkit-animation: slide-in 1s cubic-bezier(0.175, 0.885, 0.320, 1) both;
    animation: slide-in 1s cubic-bezier(0.175, 0.885, 0.320, 1) both;
}

@media screen and (max-width: 1415px) {
    .slide-bg-container {
        width: 100vw;
        align-self: center;
    }

    .projects {
        overflow-x: hidden;
    }

    .project-image-container {
        align-items: flex-start;
        background-color: var(--bg-secondary);
        margin-top: 0;
    }

    .project-image-container>img {
        color: var(--bg-primary);
    }

    .projects-content {
        order: 2;
        margin-top: 0;
    }

    .projects-nav {
        order: 1;
        margin-top: 4em;
    }
}

/**Removes hover effects on touch devices **/
@media not all and (hover: none) {
    .project-links>a:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    .projects-nav>div:hover {
        background-color: var(--bg-primary);
    }
}