.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em;
    transition: margin .5s;
}

.homepage-wrapper {
    display: flex;
    flex-direction: column;
    width: 50vw;
}

.subheading {
    font-size: 2rem;
    letter-spacing: 0.1em;
    margin: 0;
    margin-top: 4em;
    margin-bottom: 0.5em;
    font-weight: normal;
    width: 101%;
    -webkit-animation: fade-in 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: fade-in 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    transition: font-size .5s, width .5s;
}

.homepage-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40em;
    padding-bottom: 4em;
}

.heading {
    font-family: 'Teko';
    line-height: 90%;
    display: flex;
    align-items: center;
    font-size: 12rem;
    margin: 0;
    max-width: min-content;
    -webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    transition: font-size .5s;
}

.links {
    display: flex;
    width: max-content;
    gap: 1em;
    width: 100%;
    -webkit-animation: swing-in-top-fwd 2.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    animation: swing-in-top-fwd 2.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.links>a {
    cursor: pointer;
    background: none;
    border: none;
    color: var(--bg-secondary);
}

.links>a>svg {
    height: 3em;
    transition: transform .2s;
}

.greeting-container {
    display: flex;
    align-items: center;
}

.smiley-face {
    color: var(--bg-secondary);
    cursor: pointer;
    height: 2.9em;
}

.greeting-text {
    position: absolute;
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--bg-secondary);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    height: 2em;
    width: 2em;
    white-space: nowrap;
    opacity: 0;
    transition: width 1.3s, opacity .3s;
}

/**
 * ----------------------------------------
 * Text brackets
 * ----------------------------------------
 */
.heading:before {
    content: '[';
    font-size: 18rem;
    margin-right: 0.2em;
    transition: margin .5s;
}

.heading:after {
    content: ']';
    font-size: 18rem;
    margin-left: 0.2em;
    transition: margin .5s;
}

@media screen and (max-width: 1400px) {
    .greeting-text:focus {
        opacity: 1;
        width: 10em;
        transition: width .3s, opacity 1.5s;
    }
}

@media screen and (max-width: 940px) {

    .homepage {
        margin-top: 10em;
        overflow-x: hidden;
    }

    .heading {
        font-size: 6rem;
    }

    .heading:before {
        font-size: 9rem;
    }

    .heading:after {
        font-size: 9rem;
    }

    .subheading {
        font-size: 1rem;
        width: 51%;
    }

    .links {
        width: 50%;
        gap: .5em;
    }

    .links>a>svg,
    .smiley-face {
        height: 2em;
    }

    .greeting-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 475px) {
    .heading {
        font-size: 5rem;
    }

    .subheading {
        width: 40%;
    }

    .links {
        width: 40%;
    }

    .heading:before {
        font-size: 7rem;
    }

    .heading:after {
        font-size: 7rem;
    }
}

@media screen and (max-width: 390px) {
    .heading:before {
        margin-right: 0.02em;
    }

    .heading:after {
        margin-left: 0;
    }
}

/**Removes hover effects on touch devices **/
@media not all and (hover: none) {
    .links>a>svg:hover {
        transform: scale(1.2);
    }

    .greeting-text:hover {
        opacity: 1;
        width: 10em;
        transition: width .3s, opacity 1.5s;
    }
}