.about {
    display: flex;
    flex-wrap: wrap;
    gap: 5em;
    height: 100vh;
}

.about-slide-bg-container {
    position: relative;
    width: 50vw;
    transition: width .5s;
}

.about-slide-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--bg-secondary);
    -webkit-animation: slide-in 1s cubic-bezier(0.175, 0.885, 0.320, 1) both;
    animation: slide-in 1s cubic-bezier(0.175, 0.885, 0.320, 1) both;
}

.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15em;
    color: var(--bg-primary);
}

.about-info {
    padding: 1em;
    font-size: 2rem;
    width: 15em;
}

.extended {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 22em;
    font-size: 1.5rem;
    margin-right: 5em;
}

.about-heading {
    font-size: 5rem;
    font-family: 'Teko';
    transition: font-size .5s;
}

.about-paragraph {
    transition: font-size .5s;
}

.about-skills {
    z-index: 2;
    margin-left: 10em;
    margin-top: 8em;
    -webkit-animation: fade-in 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: fade-in 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    transition: margin .5s;
}

.skills-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4em;
    font-size: 1.5rem;
    transition: font-size .5s;
}

.skills-category {
    line-height: 1.8em;
}

.skills-list>li {
    list-style-type: circle;
}

.next-buttons {
    z-index: 2;
    margin-right: 5em;
}

.next-buttons>p {
    cursor: pointer;
    font-size: 3rem;
    transition: all .2s ease-in-out;
    width: min-content;
}

.about-image-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    justify-content: center;
    -webkit-animation: delay-fade-in 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: delay-fade-in 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    bottom: 0;
}

.about-image-container>img {
    width: 35em;
    transition: width .5s;
}

/**Media Queries.......... */

/** Queries for slide-bg height on diff slides **/
@media screen and (min-width: 1729px) and (max-height: 772px) {
    .about-slide-bg {
        height: auto;
    }
    
    .slide-0-height {
        height: 100%;
    }
}

@media screen and (min-width: 1729px) and (max-height: 634px) {
    .about-slide-bg {
        height: auto;
    }

    .slide-0-height {
        height: auto;
    }
}

/** -------------------------------- **/

@media screen and (max-width: 1728px) {
    .about {
        justify-content: flex-start;
    }

    .about-content {
        margin-top: 0;
    }

    .extended {
        margin-right: 0;
    }

    .about-info {
        margin-top: 8em;
    }

    .about-slide-bg {
        position: static;
        padding-bottom: 10em;
    }

    .about-image-container {
        position: fixed;
        left: 23em;
        bottom: 0;
    }

    .about-skills {
        margin-top: 8em;
        margin-left: 3em;
        width: 100vw;
    }

    .skills-section {
        margin-bottom: 5em;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 1221px) {

    .about {
        justify-content: flex-end;
    }

    .about-slide-bg-container {
        width: 100vw;
    }

    .about-content {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
    }

    .next-buttons {
        margin-right: 0;
    }

    .about-image-container {
        visibility: hidden;
    }

    .about-heading {
        font-size: 4rem;
    }

    .about-skills {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 3em;
        gap: 5em;
        margin-left: 0;
    }

    .skills-section {
        justify-content: center;
        font-size: 1.3rem;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1077px) {
    .about {
        height: auto;
    }
}

@media screen and (max-width: 895px) {
    .about-skills {
        flex-direction: column;
        gap: 0;
        align-items: center;
        justify-content: center;
        padding-bottom: 3em;
        margin-left: 0;
    }
}

@media screen and (max-width: 547px) {
    .about-info {
        width: 9em;
    }

    .about-paragraph {
        font-size: 1rem;
    }

    .skills-section {
        gap: 2em;
        font-size: 1rem;
    }
}

@media screen and (max-width: 330px) {
    #tech-heading {
        font-size: 3rem;
    }
}

/**Removes hover effects on touch devices **/
@media not all and (hover: none) {
    .next-buttons>p:hover {
        transform: scale(1.4);
        transform-origin: center;
    }
}